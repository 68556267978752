<!-- <div class="layout-container"> -->
<div (click)='gotoImpressum()'>
    <h1>Impressum</h1>
</div>

<!-- <br> -->

<div (click)='gotoDatenschutz()'>
    <h1>Datenschutzerklärung</h1>
</div>
<!-- </div> -->