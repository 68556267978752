import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rechtliches',
  templateUrl: './rechtliches.component.html',
  styleUrls: ['./rechtliches.component.scss']
})
export class RechtlichesComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  gotoImpressum() {
    this.router.navigate(['/impressum']);
  }

  gotoDatenschutz() {
    this.router.navigate(['/datenschutz']);
  }
}
